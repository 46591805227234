import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-559b2d6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"padding":"0 25px"} }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { style: {"display":"flex","align-items":"center"} }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "handleBox" }
const _hoisted_6 = {
  key: 1,
  class: "stick"
}
const _hoisted_7 = { class: "positionBox" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchOutlined = _resolveComponent("SearchOutlined")!
  const _component_Input = _resolveComponent("Input")!
  const _component_RightOutlined = _resolveComponent("RightOutlined")!
  const _component_Button = _resolveComponent("Button")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Card, {
      title: "",
      style: _normalizeStyle({ height: _ctx.height }),
      class: "card"
    }, {
      extra: _withCtx(() => []),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Input, {
            style: {"height":"40px","margin-bottom":"10px"},
            value: _ctx.searchInfo,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchInfo) = $event)),
            placeholder: "搜索通路大类",
            onChange: _ctx.search,
            onKeyup: _withKeys(_ctx.search, ["enter"]),
            allowClear: ""
          }, {
            prefix: _withCtx(() => [
              _createVNode(_component_SearchOutlined)
            ]),
            _: 1
          }, 8, ["value", "onChange", "onKeyup"])
        ]),
        _createElementVNode("div", {
          style: _normalizeStyle({ height: _ctx.contentHeight, overflow: 'auto' })
        }, [
          (_ctx.items.length)
            ? (_openBlock(), _createBlock(_component_draggable, {
                key: 0,
                modelValue: _ctx.items,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.items) = $event)),
                group: _ctx.items[0].people,
                onStart: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drag = true)),
                onEnd: _cache[3] || (_cache[3] = ($event: any) => (_ctx.drag = false)),
                "item-key": "categoryCode",
                animation: "300",
                disabled: true
              }, {
                item: _withCtx(({ element }) => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["itemBox", element.categoryCode === _ctx.activeCode ? 'active' : '']),
                    onClick: ($event: any) => (_ctx.active(element.categoryCode))
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_RightOutlined, {
                        style: {"margin":"0 10px 0 0"},
                        class: _normalizeClass(element.subordinate ? '' : 'hidden')
                      }, null, 8, ["class"]),
                      (element.categoryCode !== _ctx.editCategoryCode)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(element.categoryName), 1))
                        : (_openBlock(), _createBlock(_component_Input, {
                            key: 1,
                            value: element.categoryName,
                            "onUpdate:value": ($event: any) => ((element.categoryName) = $event),
                            placeholder: "请输入品类名称",
                            onBlur: ($event: any) => (_ctx.editCategroy(element)),
                            class: _normalizeClass([element.categoryCode, "editInput"])
                          }, null, 8, ["value", "onUpdate:value", "onBlur", "class"]))
                    ]),
                    _createElementVNode("div", _hoisted_5, [
                      (_ctx.$store.state.authc.authc.ButtonBasic_pg_basiData_channel_edit)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            type: "link",
                            disabled: _ctx.$store.state.categoryEdit,
                            class: "edit",
                            onClick: ($event: any) => (_ctx.edit(element.categoryCode, element.categoryName)),
                            style: {"font-size":"12px","color":"#008bcf","font-weight":"400"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" 编辑 ")
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"]))
                        : _createCommentVNode("", true),
                      (_ctx.$store.state.authc.authc.ButtonBasic_pg_basiData_channel_edit&&_ctx.$store.state.authc.authc.ButtonBasic_pg_basiData_channel_del)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, "|"))
                        : _createCommentVNode("", true),
                      (_ctx.$store.state.authc.authc.ButtonBasic_pg_basiData_channel_del)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 2,
                            disabled: _ctx.$store.state.categoryEdit,
                            type: "link",
                            class: "del",
                            onClick: ($event: any) => (_ctx.del(element.categoryCode)),
                            style: {"font-size":"12px","color":"#ff4d4f","font-weight":"400"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("删除")
                            ]),
                            _: 2
                          }, 1032, ["disabled", "onClick"]))
                        : _createCommentVNode("", true)
                    ])
                  ], 10, _hoisted_2)
                ]),
                _: 1
              }, 8, ["modelValue", "group"]))
            : _createCommentVNode("", true),
          (_ctx.addNewCategory)
            ? (_openBlock(), _createBlock(_component_Input, {
                key: 1,
                value: _ctx.newCategory,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newCategory) = $event)),
                placeholder: "请输入新品类的名称",
                onBlur: _ctx.blurAddNewCategory,
                class: "addnewInput"
              }, null, 8, ["value", "onBlur"]))
            : _createCommentVNode("", true)
        ], 4),
        _createElementVNode("div", _hoisted_7, [
          (_ctx.$store.state.authc.authc.ButtonBasic_pg_basiData_channel_add)
            ? (_openBlock(), _createBlock(_component_Button, {
                key: 0,
                type: "primary",
                class: "btn",
                size: "small",
                onClick: _ctx.addnew,
                disabled: _ctx.$store.state.categoryEdit
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" 新增分类 ")
                ]),
                _: 1
              }, 8, ["onClick", "disabled"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["style"])
  ]))
}