
import { defineComponent, createVNode } from "vue";
import { Input, Button, message, Card, Modal } from "ant-design-vue";
import {
  DragOutlined,
  EditOutlined,
  DeleteOutlined,
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import draggable from "vuedraggable";
import {
  channelAdd,
  channelUpdate,
  channelDelete,
  sortCategory,
  kindcategryList,
} from "@/api/terminalList.js";

export default defineComponent({
  components: {
    Button,
    Input,
    message,
    Card,
    draggable,
    DragOutlined,
    EditOutlined,
    DeleteOutlined,
    RightOutlined,
    CheckOutlined,
    SearchOutlined,
  },
  data() {
    return {
      height: "500px",
      editCategoryCode: -1,
      drag: false,
      newCategory: "",
      keepCategoryName: "",
      addNewCategory: false,
      items: [],
      activeCode: "",
      loading: false,
      contentHeight: "500px",
      searchInfo: "",
    };
  },
  methods: {
    //点击新增品牌
    addnew() {
      (this as any).$store.commit("changeCategoryEdit", true);
      this.addNewCategory = true;
      setTimeout(() => {
        let input: any = document.querySelector(".addnewInput");
        if (input) {
          input.focus();
        }
      }, 0);
    },
    //失去焦点 新增品类
    blurAddNewCategory() {
      if (this.newCategory.trim().length == 0) {
        this.addNewCategory = false;
        (this as any).$store.commit("changeCategoryEdit", false);
        return;
      }

      if (this.checkCategory(this.newCategory)) {
        return;
      }
      channelAdd({
        kindCategoryName: this.newCategory,
        kindCategoryCode: "",
      })
        .then((res) => {
          if (res.data.success) {
            this.newCategory=''
            message.success("新增成功");
            this.getList();
            this.addNewCategory = false;
          } else {
            this.addNewCategory = false;
          }
          (this as any).$store.commit("changeCategoryEdit", false);
        })
        .catch(() => {
          this.addNewCategory = false;
          (this as any).$store.commit("changeCategoryEdit", false);
        });
    },
    //获取afterSort
    getAfterSort(array) {
      array.forEach((item, index) => {
        item.afterSort = 1000 - index;
        item.beforeSort = 1000 - index;
      });
    },
    //获取一级card内容
    getList(code = "", people = 1) {
      kindcategryList().then((res: any) => {
        this.items = res.data.data;
        (this as any).items.forEach((element) => {
          element.beforeSort = element.sort;
          element.people = people;
          element.subordinate = element.isHave;
          element.categoryName = element.name;
          element.categoryCode = element.code;
        });
      });
    },
    //校验
    checkCategory(str, index = -1) {
      let flag = false;
      str = str.trim();
      let a = 0;

      this.items.forEach((ele: any, i) => {
        if (ele.categoryName === str && i !== index) {
          a++;
          if (a === 1) {
            message.warning("当前品类名称已存在,不可重复使用");
            flag = true;
          }
        }
      });
      if (str.length > 50) {
        message.warning("最多不能超过50个字符");
        flag = true;
      }
      if (str.length == 0) {
        message.warning("请输入品类名称");
        flag = true;
      }
      if (flag) {
        let input:any = document.querySelector(".editInput")||document.querySelector(".addnewInput");
        if (input) {
          input.focus();
        }
      }
      return flag;
    },
    //编辑
    edit(editCategoryCode, categoryName) {
      this.keepCategoryName = categoryName;
      this.editCategoryCode = editCategoryCode;
      setTimeout(() => {
        let input: any = document.querySelector(".editInput");
        if (input) {
          input.focus();
        }
      }, 0);
    },
    editCategroy(ele: any) {
      if (ele.categoryName == "") {
        this.getList();
        this.editCategoryCode = -1;
        return;
      }
      if (this.keepCategoryName != ele.categoryName) {
        // if (this.checkCategory(ele.categoryName, 1000 - ele.sort)) {
        //   return;
        // }
        channelUpdate({
          kindCategoryCode: ele.categoryCode,
          kindCategoryName: ele.categoryName,
        }).then((res) => {
          if (res.data.success) {
            message.success("编辑成功");
            this.getList();
            this.editCategoryCode = -1;
          }
          // else {
          //   message.error(res.data.errMessage);
          // }
        });
      } else {
        this.editCategoryCode = -1;
      }
    },
    //删除
    ///////////////////////////////////////////////////////待修改
    del(categoryCode) {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "是否确认删除?",
        cancelText: "取消",
        okText: "确认",
        onOk() {
          channelDelete({ kindCategoryCode: categoryCode }).then((res: any) => {
            if (res.data.success) {
              message.success("删除通路大类成功");
              that.getList();
              that.editCategoryCode = -1;
            }
            //  else {
            // }
          });
        },
      });
    },
    //更改排序
    handleSort() {
      this.loading = true;
      let array: any = [];
      this.items.forEach((el: any, i) => {
        el.sort = 1000 - i;
        array = [...array, { categoryCode: el.categoryCode, sort: el.sort }];
      });
      sortCategory({
        categoryParCode: "",
        sortCategory: array,
      }).then((res) => {
        if (res.data.success) {
          this.getList();
          message.success("排序成功");
        } else {
          message.error("服务器开了个小差");
        }
        this.loading = false;
      });
    },
    //点击
    active(code) {
      this.activeCode = code;
      (this as any).$store.commit("changeChannelCode1", code);
      (this as any).$store.state.channelCode4 = [];
      (this as any).$store.state.channelCode2 = [];
      (this as any).$store.state.channelCode3 = [];
      (this as any).$store.commit("changeChannelCode2", "z");
      (this as any).$store.commit("changeChannelCode3", "z");
      // (this as any).$store.commit("changeChannelCode4", "z");
      (this as any).$store.state.channel1SearchInfo = "";
      (this as any).$store.state.channel2SearchInfo = "";
      (this as any).$store.state.channel3SearchInfo = "";
      (this as any).$store.state.channel4SearchInfo = "";
    },
    //搜索功能
    search() {
      kindcategryList(this.searchInfo).then((res: any) => {
        this.items = res.data.data;
        (this as any).items.forEach((element) => {
          element.beforeSort = element.sort;
          //组1传1
          element.people = 1;
          element.subordinate = element.isHave;
          element.categoryName = element.name;
          element.categoryCode = element.code;
        });
        (this as any).$store.state.channel1SearchInfo = "";
        (this as any).$store.state.channel2SearchInfo = "";
        (this as any).$store.state.channel3SearchInfo = "";
        (this as any).$store.state.channel4SearchInfo = "";
        (this as any).$store.state.channelCode1 = [];
        (this as any).$store.commit("changeChannelCode1", "z");
        (this as any).$store.state.channelCode4 = [];
        (this as any).$store.state.channelCode2 = [];
        (this as any).$store.state.channelCode3 = [];
        (this as any).$store.commit("changeChannelCode2", "z");
        (this as any).$store.commit("changeChannelCode3", "z");
        // (this as any).$store.commit("changeChannelCode4", "z");
      });
    },
  },
  watch: {
    editCategoryCode(value) {
      if (value != -1) {
        (this as any).$store.commit("changeCategoryEdit", true);
      } else {
        (this as any).$store.commit("changeCategoryEdit", false);
      }
    },
  },

  mounted() {
    (this as any).$store.state.channel1SearchInfo = "";
    (this as any).$store.state.channel2SearchInfo = "";
    (this as any).$store.state.channel3SearchInfo = "";
    (this as any).$store.state.channel4SearchInfo = "";
    (this as any).$store.state.channelCode1 = [];
    (this as any).$store.commit("changeChannelCode1", "z");
    (this as any).$store.state.channelCode4 = [];
    (this as any).$store.state.channelCode2 = [];
    (this as any).$store.state.channelCode3 = [];
    (this as any).$store.commit("changeChannelCode2", "z");
    (this as any).$store.commit("changeChannelCode3", "z");
    this.height = document.body.clientHeight - 160 + "px";
    this.contentHeight = document.body.clientHeight - 288 + "px";
    this.getList();
  },
});
